import React, { useState } from "react";
import styled from "styled-components";
import questions from "@/dataSource/questions.json";
import { Personality } from "@/dataSource/Personality";
import Quiz from "@/components/Quiz";
import QuizHeadline from "@/components/QuizHeadline";

import bg from "@/img/test/bg.webp";
import testRippleL from "@/img/test/test-ripple-l.png";
import testRippleR from "@/img/test/test-ripple-r.png";

const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  background-image: url(${bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
`;

const Container = styled.div`
  width: 70%;
  max-width: 24rem;
  height: 80%;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 10%;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  font-family: Noto Sans TC;
`;

const RippleLeft = styled.img.attrs(() => ({
  src: testRippleL,
}))`
  margin-right: 10px;
  height: 20%;
`;

const RippleRight = styled.img.attrs(() => ({
  src: testRippleR,
}))`
  margin-left: 10px;
  height: 20%;
`;

const HeaderText = styled.div`
  color: #ffffff;
  height: 40%;
  margin-bottom: 3px;
`;

const PageIndicator = styled.div`
  padding: 3px 8px;
  color: #99ffcc;
  background-color: rgb(21, 29, 37);
  border-radius: 99em;
  border-width: 0px;
  font-size: 14px;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text-align: center;
  font-family: tenon;
`;

let selections = [];

const Test = ({ onEnd }) => {
  const [index, setIndex] = useState(0);

  const handleSelection = (value) => {
    let personality = Personality[value.representation];
    selections.push(personality);
    let nextIndex = index + 1;
    if (questions.length - 1 < nextIndex) {
      onEnd(selections);
      return;
    }
    setIndex(nextIndex);
  };

  const subject = questions[index];

  return (
    <Background>
      <Container>
        <HeaderContainer>
          <RippleLeft />
          <HeaderText>測出你的宇宙共振頻率</HeaderText>
          <RippleRight />
        </HeaderContainer>
        <QuizHeadline index={index}>
          <Quiz
            title={subject.question}
            options={subject.options}
            onSelect={handleSelection}
          />
        </QuizHeadline>
        <PageIndicator>{`${index + 1}/${questions.length}`}</PageIndicator>
      </Container>
    </Background>
  );
};

export default Test;

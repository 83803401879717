import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "@/components/Button";
import ResponsiveFonts from "@/components/ResponsiveFonts";

const Root = styled.div`
  height: 80%;
  margin-top: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: start;
  row-gap: 10%;
`;

export const TitleText = styled.div`
  color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: start;

  text-align: justify;
  text-align-last: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
`;

const Title = styled.div`
  height: 20vh;
  min-height: 100px;
  width: 100%;
  color: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;

  text-align: center;
  font-weight: 500;
  font-family: Noto Sans TC;
`;

const Spacer = styled.div`
  height: 6rem;
`;

const OptionsContainer = styled.div`
  width: 80%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  row-gap: 5%;
`;

const Quiz = ({ title, options, onSelect }) => {
  return (
    <Root>
      <Title>
        <ResponsiveFonts>{title}</ResponsiveFonts>
      </Title>
      <OptionsContainer>
        {options.map((option) => (
          <Button key={option.title} onClick={() => onSelect(option)}>
            {option.title}
          </Button>
        ))}
      </OptionsContainer>
      <Spacer />
    </Root>
  );
};

Quiz.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
};

export default Quiz;

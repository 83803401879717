import React from "react";
import styled from "styled-components";
import news from "@/img/result/news.webp";
import newTitle from "@/img/result/new-title.webp";
import newLogo from "@/img/result/new-logo.webp";
import arrow from "@/img/result/arrow.webp";

const Background = styled.div`
  padding: 3rem 1rem 3.5rem 1rem;
  max-width: 400px;

  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: start;
  row-gap: 10px;
`;

const NewsImg = styled.img.attrs(() => ({
  src: news,
}))`
  aspect-ratio: 400 / 110;
  width: 100px;
  height: 27px;
`;

const NewTitleImg = styled.img.attrs(() => ({
  src: newTitle,
}))`
  width: 80%;
`;

const RebrandTitle = styled.div`
  width: 100%;
  color: #99ffcc;

  text-align: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 18px;
  font-family: Noto Sans TC;
`;

const NewLogoImg = styled.img.attrs(() => ({
  src: newLogo,
}))`
  margin: 12%;
  width: 120px;
  height: 120px;
`;

const RebrandDesc = styled.div`
  margin: 0.5rem 0;
  width: 100%;
  color: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  row-gap: 0.5rem;

  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-family: Noto Sans TC;
`;

const ArrowImg = styled.img.attrs(() => ({
  src: arrow,
}))`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  width: 12px;
`;

const NewLookButton = styled.button`
  width: 70%;
  padding: 18px 15px;
  border-radius: 50vh;
  user-select: none;
  border-width: 0px;
  color: #333333;
  background-color: #99ffcc;

  font-family: Noto Sans TC;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

const openLink = () => {
  const url = "https://bit.ly/43hrMNm";
  window.open(url, '_self', 'noreferrer');
};

const RebrandContent = () => {
  return (
    <Background>
      <NewsImg />
      <NewTitleImg />
      <RebrandTitle>探索嶄新學習宇宙!</RebrandTitle>
      <NewLogoImg />
      <RebrandDesc>
        <span>用簡約俐落的形式，</span>
        <span>詮釋更多維度的學習，</span>
        <span>打開知識大門，</span>
        <span>一起以「學習」替生活創造選擇，</span>
        <span>成為理想的自己。</span>
      </RebrandDesc>
      <ArrowImg />
      <NewLookButton onClick={openLink}>來看 Hahow 新樣貌！</NewLookButton>
    </Background>
  );
};

export default RebrandContent;

export const Personality = {
  E: 'E',
  I: 'I',

  N: 'N',
  S: 'S',

  T: 'T',
  F: 'F',

  J: 'J',
  P: 'P',
}

import { css } from 'styled-components';

export const heading1 = ({ fontWeight = '600' } = {}) => css`
    font-weight: ${fontWeight};
    font-size: 36px;
    line-height: 44px; 
`;

export const heading2 = ({ fontWeight = '600' } = {}) => css`
    font-weight: ${fontWeight};
    font-size: 28px;
    line-height: 34px;
`;

export const heading3 = ({ fontWeight = '600' } = {}) => css`
    font-weight: ${fontWeight};
    font-size: 24px;
    line-height: 30px;
`;

export const heading4 = ({ fontWeight = '600' } = {}) => css`
    font-weight: ${fontWeight};
    font-size: 20px;
    line-height: 26px;
`;

export const heading5 = ({ fontWeight = '600' } = {}) => css`
    font-weight: ${fontWeight};
    font-size: 16px;
    line-height: 22px;
`;

export const heading6 = ({ fontWeight = '600' } = {}) => css`
    font-weight: ${fontWeight};
    font-size: 14px;
    line-height: 20px;
`;

export const generalHover = () => css`
    opacity: 1;
    transform: scale(1);
    transition: .2s opacity , 0.2s transform;
    
    &:hover {
        opacity: 0.8;
        transform: scale(0.98);
    }

    &:active, &:focus {
        opacity: 0.8;
        transform: scale(0.96);
    }
`;

import React, { useState, useRef, useEffect } from "react";
import useOnScreen from "@/utils/useOnScreen";
import styled from "styled-components";
import { Personality } from "@/dataSource/Personality";
import { outcomeMapping } from "@/utils/outcomeMapping";
import bg from "@img/result/bg.webp";
import secondLayer from "@img/result/second-layer.webp";
import cubic from "@img/result/cubic.webp";
import horizontalLine from "@/img/result/horizontal-line.webp";
import straightLine from "@/img/result/straight-line.webp";
import headerLOGO from "@/img/result/headerLOGO.webp";
import headerTitle from "@/img/result/headerTitle.webp";

import ResultButton from "@/components/Button/result-button";
import RebrandContent from "@/components/RebrandContent";
import RegisterContent from "@/components/RegisterContent";
import Separator from "@/components/Separator";

const Background = styled.div`
  width: 100%;
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: start;
  justify-content: start;
  row-gap: 1vh;

  background-image: url(${bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
`;

const Content = styled.div`
  margin-top: 5%;
  width: 100%;
  max-width: 420px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

const ResultContainer = styled.div`
  aspect-ratio: 9 / 16;
  margin-bottom: 1rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ResultContent = styled.div`
  width: 90%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

const ResultTopContainer = styled.div`
  padding: 0 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ResultDecsContainer = styled.div`
  width: 50%;
  margin: 10px 0px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  align-content: center;
  justify-content: space-between;
  row-gap: 0.1rem;
`;

const FrequencyTitleText = styled.div`
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  font-family: Noto Sans TC;
`;

const FrequencyText = styled.div`
  color: #00d1b4;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  font-weight: bold;
  font-size: 30px;
  line-height: 28px;
  font-family: tenon;
`;

const AttributeText = styled.div`
  margin-bottom: 5px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  font-family: Noto Sans TC;
`;

const PossibilityGroup = styled.div`
  position: relative;
  margin: 3px -10px 0 -2px;
  left: -2px;
`;

const PossibilityContainer = styled.div`
  color: #000000;
  border-width: 0;
  outline-width: 0;

  display: flex;
  flex-direction: row;
  align-items: end;
  align-content: start;
  row-gap: 0;
`;

const CubicImg = styled.div`
  background-color: #99ffcc;
  position: relative;
  margin: -1px;
  right: 0;
  bottom: 0;
  width: 8px;
  height: 8px;
`;

const CubicImg2 = styled.img.attrs(() => ({
  src: cubic,
}))`
  position: relative;
  left: 1px;
  margin-bottom: 3.6%;
  width: 4%;
`;

const PossibilityText = styled.div`
  margin: -1px;
  padding: 3px 4px;
  background-color: #99ffcc;
  color: #000000;
  width: fit-content;
  border-width: 0;
  outline-width: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 200;
  font-size: 11px;
  line-height: 11px;
  font-family: Noto Sans TC;

  @media (max-height: 700px) {
    font-size: 10px;
  }
`;

const PossibilityTag = styled.div`
  color: #00d1b4;
  position: absolute;
  top: 15%;
  right: 5%;
  padding: 1px;
  width: 4rem;
  border-radius: 50vh;
  background-color: #ffffff;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  font-family: Noto Sans TC;
`;

const TopImageContainer = styled.div`
  height: 100%;
  width: 46%;
  position: relative;
`;

const ResultImage = styled.img`
  width: 100%;
  aspect-ratio: 1;
`;

const PanelContainer = styled.div`
  padding: 0 0 1rem 0;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
`;

const SecondLayerImg = styled.img.attrs(() => ({
  src: secondLayer,
}))`
  position: absolute;
  bottom: 15px;
  width: 100%;
`;

const Panel = styled.div`
  mix-height: 410px;
  max-height: 440px;
  padding: 18px 13px 5px 13px;
  margin-top: -10px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

const PanelSection = styled.div`
  width: 90%;
  padding: 0 1.2rem;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  row-gap: 0.2rem;

  &:not(:first-child) {
    margin-top: 0.8rem;
  }
  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
`;

const HorizontalLine = styled.img.attrs(() => ({
  src: horizontalLine,
}))`
  width: 96%;
  opacity: 0.5;
`;

const ContentText = styled.div`
  color: #333333;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  font-family: Noto Sans TC;
`;

const Title = styled.div`
  color: #1a2755;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  font-family: Noto Sans TC;
`;

const RelativeSection = styled.div`
  padding: 0;
  margin-top: 0.8rem;
  height: 130px;

  display: flex;
  flex-direction: row;
  align-items: start;
  align-content: center;
  justify-content: space-between;
`;

const RelativesContent = styled.div`
  width: 47%;

  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: start;
  row-gap: 7px;
`;

const RelativeContentTitle = styled.div`
  color: #1a2755;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  font-family: Noto Sans TC;
`;

const RelativesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  row-gap: 7px;
`;

const RelativeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: start;
  row-gap: 3px;
`;

const RelativeImg = styled.img`
  width: 75%;
`;

const RelativeTitle = styled.div`
  color: #333333;
  font-weight: 300;
  font-size: 13px;
  line-height: 22px;
  font-family: Noto Sans TC;
`;

const StraightLine = styled.img.attrs(() => ({
  src: straightLine,
}))`
  height: 90%;
  opacity: 0.3;
`;
const ButtonContainer = styled.div`
  margin-top: -20px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: stretch;
  justify-content: start;
`;

const StickyHeaderBackground = styled.div`
  position: fixed;
  top: ${(props) => (props.isActive ? `0px` : `-60px`)};
  height: 60px;
  width: 100vw;
  background-color: #0b0f1e;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  @keyframes slidein {
    from {
      top: -60px;
    }
    to {
      top: 0px;
    }
  }

  @keyframes slideout {
    from {
      top: 0px;
    }
    to {
      top: -60px;
    }
  }

  animation: ${(props) =>
    props.isActive ? `0.4s 1 normal slidein` : `0.4s 1 normal slideout`};
`;

const HeaderImgContainer = styled.div`
  height: 100%;
  width: 43%;
  margin-left: 5%;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  row-gap: 10px;
`;

const HeaderLogoImg = styled.img.attrs(() => ({
  src: headerLOGO,
}))`
  margin-left: 8%;
  height: 50%;
  width: auto;
`;

const HeaderTitleImg = styled.img.attrs(() => ({
  src: headerTitle,
}))`
  height: 100%;
  width: auto;
`;

const HeaderScreenshotButton = styled.button`
  margin-right: 7%;
  padding: 7px 20px;
  border-radius: 50vh;
  border-width: 0px;
  color: #0b0f1e;
  background-color: #99ffcc;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-family: Noto Sans TC;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

const StickyHeader = ({ isActive, onRoute }) => {
  return (
    <StickyHeaderBackground isActive={isActive}>
      <HeaderImgContainer>
        <HeaderLogoImg />
        <HeaderTitleImg />
      </HeaderImgContainer>
      <HeaderScreenshotButton onClick={onRoute}>
        取得結果圖
      </HeaderScreenshotButton>
    </StickyHeaderBackground>
  );
};

const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

const handleTestResult = (selections) => {
  let type = "";
  for (const key of Object.keys(Personality)) {
    let elements = selections.filter((value) => value === Personality[key]);
    if (elements.length >= 2) {
      type += key;
    }
  }
  return type;
};

const Result = ({ selections, onNext }) => {
  const [displayHeader, setDisplayHeader] = useState(false);

  const ref = useRef(null);
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (window.innerWidth > 500) {
      return;
    }
    let separatorNode = document.getElementById("FirstSeparator");
    var separatorNodeTop = separatorNode.getBoundingClientRect().top;
    if (isVisible) {
      if (displayHeader) setDisplayHeader(false);
    } else {
      if (separatorNodeTop > 0) return;
      if (!displayHeader) setDisplayHeader(true);
    }
  }, [isVisible]);

  let type = handleTestResult(selections);
  let outcome = outcomeMapping(type);

  const handleScreenshot = async () => {
    scrollToTop();
    onNext(type);
  };

  return (
    <div>
      <Background>
        <Content>
          <ResultContainer id="ResultContainer">
            <ResultContent>
              <ResultTopContainer>
                <ResultDecsContainer>
                  <FrequencyTitleText>你的宇宙共振頻率是</FrequencyTitleText>
                  <FrequencyText>{outcome.frequency}</FrequencyText>
                  <AttributeText>{outcome.trait}</AttributeText>
                  <PossibilityGroup>
                    <PossibilityContainer>
                      <PossibilityText>
                        地球上有 {outcome.possibility} 的人與你接收
                      </PossibilityText>
                      <CubicImg />
                      <CubicImg2 />
                    </PossibilityContainer>
                    <PossibilityContainer>
                      <PossibilityText>到同樣的頻率</PossibilityText>
                      <CubicImg />
                    </PossibilityContainer>
                  </PossibilityGroup>
                </ResultDecsContainer>
                <TopImageContainer>
                  {outcome.rare ? (
                    <PossibilityTag>＃超罕見</PossibilityTag>
                  ) : (
                    <></>
                  )}
                  <ResultImage src={outcome.img} />
                </TopImageContainer>
              </ResultTopContainer>
              <PanelContainer>
                <SecondLayerImg />
                <Panel>
                  <PanelSection>
                    <ContentText>{outcome.desc}</ContentText>
                  </PanelSection>
                  <HorizontalLine />
                  <PanelSection>
                    <Title>在觀測者眼中，你是...</Title>
                    <ContentText>{outcome.impression}</ContentText>
                  </PanelSection>
                  <HorizontalLine />
                  <PanelSection>
                    <Title>在未來的宇宙探索中，你應該...</Title>
                    <ContentText>{outcome.should}</ContentText>
                  </PanelSection>
                  <HorizontalLine />
                  <RelativeSection>
                    <RelativesContent>
                      <RelativeContentTitle>
                        與你最契合的共振頻率
                      </RelativeContentTitle>
                      <RelativesContainer>
                        {outcome.match.map((value) => {
                          const relative = outcomeMapping(value);
                          return (
                            <RelativeContainer key={value}>
                              <RelativeImg src={relative.img} />
                              <RelativeTitle>
                                {relative.frequency}
                              </RelativeTitle>
                            </RelativeContainer>
                          );
                        })}
                      </RelativesContainer>
                    </RelativesContent>
                    <StraightLine />
                    <RelativesContent>
                      <RelativeContentTitle>
                        與你最互斥的共振頻率
                      </RelativeContentTitle>
                      <RelativesContainer>
                        {outcome.incompatible.map((value) => {
                          const incompatible = outcomeMapping(value);
                          return (
                            <RelativeContainer key={value}>
                              <RelativeImg src={incompatible.img} />
                              <RelativeTitle>
                                {incompatible.frequency}
                              </RelativeTitle>
                            </RelativeContainer>
                          );
                        })}
                      </RelativesContainer>
                    </RelativesContent>
                  </RelativeSection>
                </Panel>
              </PanelContainer>
              <ButtonContainer id="CTA-button">
                <ResultButton id="result-button" onClick={handleScreenshot}>
                  取得結果圖
                </ResultButton>
              </ButtonContainer>
            </ResultContent>
          </ResultContainer>
          <Separator ref={ref} id="FirstSeparator" />
          <RebrandContent />
          <Separator />
          <RegisterContent />
        </Content>
      </Background>
      <StickyHeader isActive={displayHeader} onRoute={handleScreenshot} />
    </div>
  );
};

export default Result;

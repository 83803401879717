import React from "react";
import styled from "styled-components";
import arrowL from "@/img/screenshot/arrowL.webp";
import arrowR from "@/img/screenshot/arrowR.webp";
import FB from "@/img/screenshot/FB.webp";
import IG from "@/img/screenshot/IG.webp";
import LINE from "@/img/screenshot/LINE.webp";
import greenLine from "@/img/screenshot/green-line.webp";
import grayLine from "@/img/screenshot/gray-line.webp";
import plaid from "@/img/screenshot/plaid.webp";
import bg from "@/img/result/bg.webp";

import RebrandContent from "@/components/RebrandContent";
import RegisterContent from "@/components/RegisterContent";
import Separator from "@/components/Separator";

import HahowGameResult10600MHz from "@/img/screenshot/HahowGameResult10600MHz.png";
import HahowGameResult1420MHz from "@/img/screenshot/HahowGameResult1420MHz.png";
import HahowGameResult408MHz from "@/img/screenshot/HahowGameResult408MHz.png";
import HahowGameResult1400MHz from "@/img/screenshot/HahowGameResult1400MHz.png";
import HahowGameResult2380MHz from "@/img/screenshot/HahowGameResult2380MHz.png";
import HahowGameResult327MHz from "@/img/screenshot/HahowGameResult327MHz.png";
import HahowGameResult960MHz from "@/img/screenshot/HahowGameResult960MHz.png";
import HahowGameResult499MHz from "@/img/screenshot/HahowGameResult499MHz.png";
import HahowGameResult1500MHz from "@/img/screenshot/HahowGameResult1500MHz.png";
import HahowGameResult820MHz from "@/img/screenshot/HahowGameResult820MHz.png";
import HahowGameResult23600MHz from "@/img/screenshot/HahowGameResult23600MHz.png";
import HahowGameResult45MHz from "@/img/screenshot/HahowGameResult45MHz.png";
import HahowGameResult142MHz from "@/img/screenshot/HahowGameResult142MHz.png";
import HahowGameResult333MHz from "@/img/screenshot/HahowGameResult333MHz.png";
import HahowGameResult1MHz from "@/img/screenshot/HahowGameResult1MHz.png";
import HahowGameResult5500MHz from "@/img/screenshot/HahowGameResult5500MHz.png";

const Background = styled.div`
  height: auto;
  width: 100%;
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  background-image: url(${bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
`;

const Content = styled.div`
  width: 100%;
  max-width: 420px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  row-gap: 0;
`;

const ScreenshotSection = styled.div`
  height: fit-content;
  margin-bottom: 50px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
`;

const TopContent = styled.div`
  position: relative;
  margin: 20px 0;
  height: fit-content;
  width: 90%;
  max-width: 450px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  row-gap: 15px;
`;

const TopBackground = styled.div`
  position: absolute;
  width: 100%;
  max-width: 500px;
  height: 100%;

  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const PlaidSpacer = styled.div`
  height: 10%;
  width: 100%;
`;

const Plaid = styled.div`
  width: 100%;
  max-width: 500px;
  height: auto;
  min-height: 420px;
  max-height: 450px;

  border-radius: 10px;

  background-image: url(${plaid});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
`;

const TopContainer = styled.div`
  border-radius: 10px;
  width: 65vw;
  max-width: 300px;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  row-gap: 0;

  @media (max-height: 580px) {
    width: 50vw;
  }
`;

const ScreenshotImg = styled.img`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  aspect-ratio: 9 / 16;
  width: 100%;

  max-width: 100%;
  max-height: 96%;
  border-color: #888888;
`;

const SaveButton = styled.button`
  width: 100%;
  height: 40px;
  padding: 15px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-width: 0px;
  outline: 0;
  color: #ffffff;
  background-color: #000000;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  font-family: Noto Sans TC;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

const InviteFriendsContainer = styled.div`
  width: 60%;
  color: #00ada2;
  font-family: Noto Sans TC;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

const ArrowLeftImg = styled.img.attrs(() => ({
  src: arrowL,
}))`
  width: 10%;
`;

const ArrowRightImg = styled.img.attrs(() => ({
  src: arrowR,
}))`
  width: 10%;
`;

const ShareContainer = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

const SocialContainer = styled.button`
  width: 33%;
  height: 30px;
  color: #00ada2;
  background-color: rgb(0, 0, 0, 0);
  border-width: 0;
  font-family: tenon;
  font-weight: 300;
  font-size: 12px;
  line-height: 22px;
  text-align: center;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const Spacer = styled.div`
  width: 10%;
`;

const IGImg = styled.img.attrs(() => ({
  src: IG,
}))`
  height: 100%;
`;

const LineImg = styled.img.attrs(() => ({
  src: LINE,
}))`
  height: 100%;
`;

const FBImg = styled.img.attrs(() => ({
  src: FB,
}))`
  height: 100%;
`;

const GreenLineImg = styled.img.attrs(() => ({
  src: greenLine,
}))`
  height: 70%;
  width: 0.25%;
`;

const FindMatchContainer = styled.div`
  width: 100%;
  color: #666666;
  font-family: Noto Sans TC;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  text-align: center;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

const GrayLine = styled.img.attrs(() => ({
  src: grayLine,
}))`
  width: 18%;
`;

const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    );
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

const handleRoute = async (destination) => {
  let url = encodeURIComponent(window.location.href);
  switch (destination) {
    case "IG":
      window.open(
        "https://www.instagram.com/create/story",
        "_self",
        "noreferrer"
      );
      break;
    case "LINE":
      const text = "測出你的宇宙共振頻率";
      window.open(
        `https://line.me/R/share?text=${text}${url}`,
        "_self",
        "noreferrer"
      );
      break;
    case "FB":
      if (isMobile.any()) {
        window.open(`https://www.facebook.com`, "_self", "noreferrer");
      } else {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${url}`,
          "_self",
          "noreferrer"
        );
      }
      break;
    default:
      break;
  }
};

const srcFromType = (type) => {
  switch (type) {
    case "ENTJ":
      return HahowGameResult10600MHz;
    case "ESTJ":
      return HahowGameResult1420MHz;
    case "ESTP":
      return HahowGameResult408MHz;
    case "ISFP":
      return HahowGameResult1400MHz;
    case "ENFJ":
      return HahowGameResult2380MHz;
    case "ISTP":
      return HahowGameResult327MHz;
    case "INFP":
      return HahowGameResult960MHz;
    case "INTP":
      return HahowGameResult499MHz;
    case "INTJ":
      return HahowGameResult1500MHz;
    case "ISTJ":
      return HahowGameResult820MHz;
    case "INFJ":
      return HahowGameResult23600MHz;
    case "ESFP":
      return HahowGameResult45MHz;
    case "ENTP":
      return HahowGameResult142MHz;
    case "ENFP":
      return HahowGameResult333MHz;
    case "ESFJ":
      return HahowGameResult1MHz;
    case "ISFJ":
      return HahowGameResult5500MHz;
    default:
      return "";
  }
};

export const Screenshot = ({ type }) => {
  return (
    <Background>
      <Content>
        <ScreenshotSection>
          <TopBackground>
            <PlaidSpacer />
            <Plaid />
          </TopBackground>
          <TopContent>
            <TopContainer>
              <ScreenshotImg src={srcFromType(type)} alt="截圖" />
              <SaveButton>長按上方存圖</SaveButton>
            </TopContainer>
            <InviteFriendsContainer>
              <ArrowLeftImg />
              邀請朋友一起來測看看
              <ArrowRightImg />
            </InviteFriendsContainer>
            <ShareContainer>
              <SocialContainer onClick={() => handleRoute("IG")}>
                <IGImg />
                <Spacer />
                instagram
              </SocialContainer>
              <GreenLineImg />
              <SocialContainer onClick={() => handleRoute("LINE")}>
                <LineImg />
                <Spacer />
                LINE
              </SocialContainer>
              <GreenLineImg />
              <SocialContainer onClick={() => handleRoute("FB")}>
                <FBImg />
                <Spacer />
                Facebook
              </SocialContainer>
            </ShareContainer>
            <FindMatchContainer>
              <GrayLine />
              找到與你契合的宇宙頻率
              <GrayLine />
            </FindMatchContainer>
          </TopContent>
        </ScreenshotSection>
        <Separator />
        <RebrandContent />
        <Separator />
        <RegisterContent />
      </Content>
    </Background>
  );
};

export default Screenshot;

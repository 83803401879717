import styled from "styled-components";
import { TitleText } from "@/components/Quiz";
import { heading5, heading6 } from "@/utils/cssMixins";
import keyVisionTitle from "@/img/landing/key-vision-title.png";
import keyVisionTitlePrefix from "@/img/landing/key-vision-title-prefix.png";
import keyVisionSubtitle from "@/img/landing/key-vision-subtitle.png";
import brandLogo from "@/img/landing/brand-logo.png";
import bg from "@/img/landing/key-vision.webp";
import buttonBg from "@/img/landing/key-vision-button.webp";
import arrowL from "@/img/landing/arrow-l.svg";
import arrowR from "@/img/landing/arrow-r.svg";

const Background = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  background-image: url(${bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
`;

const Content = styled.div`
  max-width: 600px;
  width: 70%;
  height: 100%;
  padding-top: 14vh;
  padding-bottom: 10vh;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: start;
`;

const TopImageContainer = styled.div`
  position: relative;
`;

const TopTitleImg = styled.img.attrs(() => ({
  src: keyVisionTitle,
}))`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70vmin;
  max-width: 400px;
  pointer-events: none;
`;

const TopTitlePrefixImg = styled.img.attrs(() => ({
  src: keyVisionTitlePrefix,
}))`
  position: absolute;
  display: block;
  left: 5%;
  top: 16%;
  width: 20%;
  max-width: 80px;
  pointer-events: none;

  @media (prefers-reduced-motion: no-preference) {
    animation: move infinite 1s ease-in-out;
  }

  @keyframes move {
    0% {
      transform: translate(0, 20%);
    }
    50% {
      transform: translate(0);
    }
    100% {
      transform: translate(0, 20%);
    }
  }
`;

const Spacer1 = styled.div`
  height: 5%;
`;

const SubtitleButton = styled.button`
  width: 100%;
  position: relative;
  border-width: 0;
  background-color: rgb(0, 0, 0, 0);

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: start;

  &:hover {
    cursor: pointer;
  }
`;

const SubtitleBackground = styled.img.attrs(() => ({
  src: keyVisionSubtitle,
}))`
  width: 100%;
  max-width: 300px;
`;
const SubtitleText = styled.div`
  position: absolute;
  top: 32%;
  width: 100%;
  color: #000000;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  ${heading5({ fontWeight: 500 })}
  text-align: center;
  font-family: Noto Sans TC;
  text-align: justify;
  text-align-last: center;
`;

const Spacer2 = styled.div`
  height: 10%;
`;

const DescText = styled(TitleText)`
  max-width: 16rem;
  flex-direction: column;
  row-gap: 0.3rem;
  ${heading6({ fontWeight: 400 })}
  text-align: center;
  font-family: Noto Sans TC;

  @media (min-height: 700px) {
    ${heading5({ fontWeight: 400 })}
  }
`;

const Spacer3 = styled.div`
  height: 7%;
`;

const StyledButton = styled.button`
  width: 210px;
  max-width: 300px;
  height: 100%;
  max-height: 100px;
  padding: 30px 20px;
  border-width: 0px;
  outline: 0;
  color: #333333;
  background-color: rgb(0, 0, 0, 0);

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-around;

  background-image: url(${buttonBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-height: 700px) {
    width: 150px;
    max-width: 9rem;
    height: 70px;
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    background-color: rgb(0, 0, 0, 0);
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: scale infinite 1s ease-in-out;
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.03);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const ButtonText = styled.div`
  margin-top: -2px;

  font-family: Noto Sans TC;
  font-weight: 500;
  font-size: 24px;
  line-height: 16px;
  text-align: center;

  @media (max-height: 700px) {
    margin-top: -1px;
    font-size: 16px;
    line-height: 12px;
  }
`;

const ArrowLImg = styled.img.attrs(() => ({
  src: arrowL,
}))`
  margin-top: 2px;
  width: 6%;
`;

const ArrowRImg = styled.img.attrs(() => ({
  src: arrowR,
}))`
  margin-top: 2px;
  width: 6%;
`;

const BottomBrandContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spacer4 = styled.div`
  height: 2vh;
`;

const BrandLogo = styled.img.attrs(() => ({
  src: brandLogo,
}))`
  position: absolute;
  width: 7rem;
  bottom: 4%;
`;

const Landing = ({ onNext }) => {
  return (
    <Background>
      <Content>
        <TopImageContainer>
          <TopTitlePrefixImg />
          <TopTitleImg />
        </TopImageContainer>
        <Spacer1 />
        <SubtitleButton onClick={onNext}>
          <SubtitleBackground />
          <SubtitleText>了解自己神秘的一面</SubtitleText>
        </SubtitleButton>
        <Spacer2 />
        <DescText>
          <span>如果每個人都是一種宇宙頻率</span>
          <span>你會是什麼樣的電波訊號？</span>
          <span>誰又是與你頻率最契合的另一半？</span>
        </DescText>
        <Spacer3 />
        <StyledButton onClick={onNext}>
          <ArrowLImg />
          <ButtonText>開始測驗</ButtonText>
          <ArrowRImg />
        </StyledButton>
        <Spacer4 />
        <BottomBrandContainer>
          <BrandLogo />
        </BottomBrandContainer>
      </Content>
    </Background>
  );
};

export default Landing;

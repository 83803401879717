import makeResponsiveComponent from '@/utils/makeResponsiveComponent'

export default makeResponsiveComponent(
  [
    {
      constraint: 'min',
      width: '0px',
      rules: `
        font-size: 0.7rem;
        line-height: 1rem;
      `
    },
    {
      constraint: 'min',
      width: '310px',
      rules: `
        font-size: 0.9rem;
        line-height: 1.2rem;
      `
    },
    {
      constraint: 'min',
      width: '340px',
      rules: `
        font-size: 0.93rem;
        line-height: 1.4rem;
      `
    },
    {
      constraint: 'min',
      width: '390px',
      rules: `
        font-size: 1.05rem;
        line-height: 1.4rem;
      `
    }, {
      constraint: 'min',
      width: '640px',
      rules: `
        font-size: 1.1rem;
        line-height: 1.7rem;
      `
    }
  ]
)

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Root = styled.div`
  padding: 0 20%;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: center;
  justify-content: center;

  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  background-clip: border-box;
`;

const QuizHeadline = ({ index, children }) => {

  const src = require(`@/img/test/test-q${index + 1}.png`)
  
  return (
    <Root style={{
      backgroundImage: `url(${src})`
    }}>
      {children}
    </Root>
  );
};

QuizHeadline.propTypes = {
  index: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default QuizHeadline;

import React from "react";
import styled from "styled-components";

import bg from "@/img/loading/bg.webp";
import greenDot from "@/img/loading/green-dot.png";

const Background = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  background-image: url(${bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
`;

const Container = styled.div`
  width: 50%;
  max-width: 15rem;
  height: 10%;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  row-gap: 10px;
`;

const ImageContainer = styled.div`
  width: 60%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  row-gap: 20px;
`;

const progress = (index, opacity) => `
  ${index * 20}% {
    opacity: ${opacity};
  }
`;

const twinkle = (index) => `
  @media (prefers-reduced-motion: no-preference) {
    animation: twinkle${index} infinite 1.7s ease-in-out;
  }

  @keyframes twinkle${index} {
    ${[0, 1, 2, 3, 4, 5]
      .map((i) => progress(i, i > 4 - index ? 1 : 0))
      .join("")}
  }
`;

const CenterImage = styled.img.attrs(() => ({
  src: greenDot,
}))`
  margin: -10px;
  width: 2rem;
`;

const SignalImage = styled.img`
  max-height: 100%;
  display: block;
  object-fit: cover;

  ${(props) => twinkle(props.index)};
`;

SignalImage.defaultProps = {
  index: 0,
};

export const HeaderText = styled.div`
  flex-direction: column;
  font-size: 1.1rem;
  color: #ffffff;
  text-align: center;
  line-height: 30px;

  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  font-family: Noto Sans TC;
`;

export const LoadingAnimation = () => {
  return (
    <ImageContainer>
      {[0, 1, 2, 3, 4].map((index) => {
        const src = require(`@/img/loading/signal-${index + 1}.png`);
        return (
          <SignalImage
            key={`index left ${index}`}
            src={src}
            index={index}
            style={{ height: `${(5 - index) * 10}%` }}
          />
        );
      })}
      <CenterImage />
      {[4, 3, 2, 1, 0].map((index) => {
        const src = require(`@/img/loading/signal-${index + 1}.png`);
        return (
          <SignalImage
            key={`index right ${index}`}
            src={src}
            index={index}
            style={{ height: `${(5 - index) * 10}%` }}
          />
        );
      })}
    </ImageContainer>
  );
};

const Loading = ({ onNext }) => {
  setTimeout(() => {
    onNext();
  }, 2 * 1000);

  return (
    <Background>
      <Container>
        <LoadingAnimation />
        <div>
          <HeaderText>正在接收</HeaderText>
          <HeaderText>你的宇宙共振頻率</HeaderText>
        </div>
      </Container>
    </Background>
  );
};

export default Loading;

import styled from "styled-components";
import separator from "@/img/result/separator.webp";

const Separator = styled.img.attrs(() => ({
  src: separator,
}))`
  width: 90%;
`;

export default Separator;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ResponsiveFonts from "@/components/ResponsiveFontsOptions";
import button from "@/img/test/button.webp";

const StyledButton = styled.button`
  position: relative;
  aspect-ratio: 20 / 7;
  width: 100%;
  min-width: 180px;
  max-width: 280px;
  height: auto;
  min-height: 72px;
  border-width: 0px;
  color: #000000;
  background-color: rgb(0, 0, 0, 0);
  outline: none;
  box-shadow: none;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  background-image: url(${button});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
`;

const ButtonText = styled.div`
  padding: 0.5rem 1.2rem;
  font-weight: 400;
  color: #333333;
  font-family: Noto Sans TC;
`;

const Button = ({ onClick, children }) => (
  <StyledButton onClick={onClick}>
    <ButtonText>
      <ResponsiveFonts>{children}</ResponsiveFonts>
    </ButtonText>
  </StyledButton>
);

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Button;

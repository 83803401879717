import React from "react";
import styled from "styled-components";
import ResultButton from "@/components/Button/result-button";
import becomeMember from "@/img/result/become-member.webp";
import oneThousand from "@/img/result/1000.webp";
import frog from "@/img/result/frog.webp";

const Background = styled.div`
  padding: 2rem 1rem;
  max-width: 400px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  row-gap: 0.3rem;
`;

const BecomeMemberImg = styled.img.attrs(() => ({
  src: becomeMember,
}))`
  margin-top: 1.2rem;
  width: 75%;
`;

const RegisterTitleContainer = styled.div`
  width: 85%;
  color: #ffffff;

  font-size: 30px;
  font-weight: 700;
  line-height: 26px;
  font-family: Noto Sans TC;

  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-around;
`;

const OneThousandImg = styled.img.attrs(() => ({
  src: oneThousand,
}))`
  margin-top: 5px;
  width: 40%;
`;

const FrogImg = styled.img.attrs(() => ({
  src: frog,
}))`
  margin: 1.3rem 0;
  width: 85%;
`;

const RegisterDesc = styled.div`
  margin: 0.5rem 0 1rem 0;
  width: 100%;
  color: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  row-gap: 0.5rem;

  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-family: Noto Sans TC;
`;

const openLink = () => {
  const url = "http://bit.ly/3mdzesh";
  window.open(url, '_self', 'noreferrer');
};

const RegisterContent = () => {
  return (
    <Background>
      <BecomeMemberImg />
      <RegisterTitleContainer>
        送
        <OneThousandImg />
        購課金！
      </RegisterTitleContainer>
      <FrogImg />
      <RegisterDesc>
        <span>期待遇見更好的自己</span>
        <span>卻不知道從何開始？</span>
        <span>Hahow 超過 1000+ 堂線上課程</span>
        <span>從職場到生活全領域知識內容</span>
        <span>探索屬於你的無限可能</span>
      </RegisterDesc>
      <ResultButton onClick={openLink}>註冊領購課金</ResultButton>
    </Background>
  );
};

export default RegisterContent;

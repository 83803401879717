import styled from "styled-components";
import buttonBg from "@/img/result/buttonBg.webp";

const ResultButton = styled.button`
  width: 75%;
  max-width: 300px;
  height: 100%;
  max-height: 100px;
  padding: 30px 15px;
  border-width: 0px;
  outline: 0;
  color: #333333;
  background-color: rgb(0, 0, 0, 0);

  background-image: url(${buttonBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  font-family: Noto Sans TC;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

export default ResultButton;

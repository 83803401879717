import React, { useState } from "react";
import styled from "styled-components";
import "./App.css";

import Landing from "@/pages/Landing";
import Test from "@/pages/Test";
import Loading from "@/pages/Loading";
import Result from "@/pages/Result";
import Screenshot from "@/pages/Screenshot";

const AppRoot = styled.div`
  height: 100%;
  width: 100%;
`;

const Page = {
  landing: "landing",
  testing: "testing",
  loading: "loading",
  result: "result",
  screenshot: "screenshot",
};

const renderPage = (page, setPage) => {
  switch (page.type) {
    case Page.landing:
      let selections = JSON.parse(sessionStorage.getItem("selections"));
      if (selections) {
        return (
          <Result
            selections={selections}
            onNext={(type) => {
              setPage({
                type: Page.screenshot,
                data: type,
              });
            }}
          />
        );
      } else {
        return (
          <Landing
            onNext={() => {
              setPage({
                type: Page.testing,
              });
            }}
          />
        );
      }
    case Page.testing:
      return (
        <Test
          onEnd={(selections) => {
            setPage({
              type: Page.loading,
              data: selections,
            });
          }}
        />
      );
    case Page.loading:
      return (
        <Loading
          onNext={() => {
            setPage({
              type: Page.result,
              data: page.data,
            });
          }}
        />
      );
    case Page.result:
      sessionStorage.setItem("selections", JSON.stringify(page.data));
      return (
        <Result
          selections={page.data}
          onNext={(type) => {
            setPage({
              type: Page.screenshot,
              data: type,
            });
          }}
        />
      );
    case Page.screenshot:
      return <Screenshot type={page.data} />;
    default:
      return;
  }
};

const App = () => {
  const [page, setPage] = useState({
    type: Page.landing,
  });

  return <AppRoot>{renderPage(page, setPage)}</AppRoot>;
};

export default App;

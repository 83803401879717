import makeResponsiveComponent from '@/utils/makeResponsiveComponent'

export default makeResponsiveComponent(
  [
    {
      constraint: 'min',
      width: '0px',
      rules: `
        font-size: 16px;
        line-height: 30px;
      `
    }, {
      constraint: 'min',
      width: '390px',
      rules: `
        font-size: 18px;
        line-height: 32px;
      `
    }, {
      constraint: 'min',
      width: '640px',
      rules: `
        font-size: 20px;
        line-height: 34px;
      `
    }
  ]
)
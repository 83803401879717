import ENTJ from "@/img/result/ENTJ.webp";
import ESTJ from "@/img/result/ESTJ.webp";
import ESTP from "@/img/result/ESTP.webp";
import ISFP from "@/img/result/ISFP.webp";
import ENFJ from "@/img/result/ENFJ.webp";
import ISTP from "@/img/result/ISTP.webp";
import INFP from "@/img/result/INFP.webp";
import INTP from "@/img/result/INTP.webp";
import INTJ from "@/img/result/INTJ.webp";
import ISTJ from "@/img/result/ISTJ.webp";
import INFJ from "@/img/result/INFJ.webp";
import ESFP from "@/img/result/ESFP.webp";
import ENTP from "@/img/result/ENTP.webp";
import ENFP from "@/img/result/ENFP.webp";
import ESFJ from "@/img/result/ESFJ.webp";
import ISFJ from "@/img/result/ISFJ.webp";

export const outcomeMapping = (type) => {
  switch (type) {
    case "ENTJ":
      return {
        frequency: "10600 MHz",
        rare: true,
        trait: "果斷 / 領導 / 自信",
        possibility: "1.8%",
        desc: "10,600 MHz 是開創性的存在，你總能任性的堅持著自己的信念、勇於承擔責任與挑戰，並在不知不覺中一直突破自己，還能感染身邊的人一起成長。",
        impression: "很有想法，能夠勇敢的追尋自己的目標，外加有點完美主義。",
        should:
          "持續散發你的自信與熱忱，保持理解與同理能讓你成為更棒的領導者。",
        match: ["INTP", "ESFJ"],
        incompatible: ["ISFP", "ESFP"],
        img: ENTJ,
      };
    case "ESTJ":
      return {
        frequency: "1420 MHz",
        rare: false,
        trait: "直率 / 效率 / 責任感",
        possibility: "8.7%",
        desc: "1420 MHz 被認為是尋找物星生物的關鍵頻率之一，你對於完成目標有強大的動力，講求邏輯分析、實事求是，計畫被打亂是你的個人雷點。",
        impression:
          "自信果斷的領導者，但有時候自尊性太強，讓人有種勸不得的感覺。",
        should: "冒險能為你帶來新的刺激，去嘗試一件你觀望已久卻還未行動的事！",
        match: ["ISTP", "ENFJ"],
        incompatible: ["INFP", "ESFP"],
        img: ESTJ,
      };
    case "ESTP":
      return {
        frequency: "408 MHz",
        rare: false,
        trait: "說服 / 大膽 / 冒險",
        possibility: "4.3%",
        desc: "408 MHz 源自於宇宙大爆炸，你天生是人群中的焦點，喜歡冒險與挑戰，能夠振奮團隊中的士氣；同時擁有超高的行動力，缺點是缺乏耐心。",
        impression: "能言善道，能夠輕易地說服別人，活在當下的態度讓人嚮往。",
        should: "大方展現你的獨特天賦，面對喜愛的事物全力以赴吧！",
        match: ["ENFP", "ISTJ"],
        incompatible: ["INFJ", "ESFP"],
        img: ESTP,
      };
    case "ISFP":
      return {
        frequency: "1400 MHz",
        rare: false,
        trait: "細心 / 包容 / 活在當下",
        possibility: "8.8%",
        desc: "1400 MHz 擁有能穿透許多特質的特性，你擁有強大的包容力，但對於「美」這件事卻有獨特的堅持，能在創造性的任務中表現出色，但容易受情緒影響。",
        impression: "天生的藝術家，隨心而行，活在當下。",
        should: "跳脫框架的思考，利用自己的優勢做事，努力告別拖延症！",
        match: ["ESFJ", "INTP"],
        incompatible: ["ENTJ", "INTJ"],
        img: ISFP,
      };
    case "ENFJ":
      return {
        frequency: "2380 MHz",
        rare: false,
        trait: "開朗 / 同理 / 幫助",
        possibility: "2%",
        desc: "2380 MHz 被認為是從水星發出的，你熱情健談，重視團隊合作，同時擁有敏銳的觀察力，能夠同理夥伴與朋友的想法，但也因此容易走心。",
        impression: "在團體中能夠凝聚人心的領導者，在你身邊都會不自覺被感染。",
        should: "照顧別人的同時，也要把注意力放回自己身上，避免過度操勞！",
        match: ["INFP", "ESTJ"],
        incompatible: ["ISTP", "ENTP"],
        img: ENFJ
      };
    case "ISTP":
      return {
        frequency: "327 MHz",
        rare: false,
        trait: "好奇 / 公平 / 難以捉摸",
        possibility: "5.4%",
        desc: "327 MHz 在宇宙中的觀測扮演重要角色，你看似內向，但其實內心充滿想法、善於思考與推理，特別喜歡自己動手嘗試新事物，但有點三分鐘熱度。",
        impression:
          "沈默寡言，不喜歡被約束，不用太多關心自己可以把任務完美完成！",
        should: "學習一個新領域的知識，跨領域的挑戰為你找到新的靈感！",
        match: ["INFP", "ESTJ"],
        incompatible: ["ISTP", "INFJ"],
        img: ISTP,
      };
    case "INFP":
      return {
        frequency: "960 MHz",
        rare: false,
        trait: "溫柔 / 誠懇 / 奉獻",
        possibility: "4.4%",
        desc: "960 MHz 常被用於尋找其他天體，在你的眼中總能看見事情美好的那一面，擁有自己堅守的價值觀，不願意隨波逐流，經常會做出讓人意想不到的舉動。",
        impression:
          "溫和好相處，但有點活在自己的世界裡面，不知道都在想些什麼？",
        should: "往你自己心中的理想目標繼續向前，你的善良誠懇是最棒的禮物。",
        match: ["ENFJ", "ISTP"],
        incompatible: ["ISTJ", "ESTJ"],
        img: INFP,
      };
    case "INTP":
      return {
        frequency: "499.4 MHz",
        rare: false,
        trait: "分析 / 上進 / 聰穎",
        possibility: "3.3%",
        desc: "499.4 MHz 又稱為脈衝星，你喜歡追求知識和探究事物的本質，理性卻又矛盾，常常把自己的理論想法全部推翻，只為了尋找出完美的解答。",
        impression: "在職場上可靠又聰明，很有自己的一套想法，不願意平庸度日。",
        should: "擁有自己的立場很棒，但適時聆聽別人的想法與建議也能獲得新的觀點與啟發。",
        match: ["ENTJ", "ISFP"],
        incompatible: ["ESFJ", "ISFJ"],
        img: INTP,
      };
    case "INTJ":
      return {
        frequency: "1500 MHz",
        rare: false,
        trait: "獨立 / 嚴謹 / 果斷",
        possibility: "2.1%",
        desc: "1500 MHz 能被應用在雷達、通訊中，擁有敏銳的直覺、熱衷於設計計畫並要求要完美執行，常因覺得別人達不到自己的標準而選擇自己來。",
        impression: "難相處但很聰穎，能夠實踐自己想法與挑戰性的工作。",
        should: "把握你過人學習力，繼續吸收知識、往理想更靠近一步。",
        match: ["ISFJ", "ENTJ"],
        incompatible: ["ESFP", "ISFP"],
        img: INTJ,
      };
    case "ISTJ":
      return {
        frequency: "820 MHz",
        rare: false,
        trait: "勤奮 / 行動 / 正直",
        possibility: "11.8%",
        desc: "820 MHz 被利用於觀測星系，喜歡遵循已經訂立好的規範，能夠以堅持不懈的精神完成份內工作，擁有強大的責任感，最討厭別人說謊。",
        impression: "大部分的時候很冷靜，但對於自己堅持的事情格外固執。",
        should: "秉持細膩積極的態度，擁抱改變的可能性能讓你成長得更快速。",
        match: ["ENTP", "INFJ"],
        incompatible: ["ENFP", "INFP"],
        img: ISTJ,
      };
    case "INFJ":
      return {
        frequency: "23600 MHz",
        rare: true,
        trait: "神秘 / 使命 / 感性",
        possibility: "1.5%",
        desc: "23600 MHz 擁有強大的能量，你對於世界有許多美好的想像，討厭爭執，在安靜的外表有著難以捉摸的想法與感受，對人有敏銳的觀察。",
        impression: "第六感超級準，只要跟你對到眼，好像秘密都會被看光。",
        should:
          "遇到不同議題也要繼續保持心中的善念，只要付出實踐就能收穫改變。",
        match: ["ISTJ", "ENFP"],
        incompatible: ["ISTP", "ESTP"],
        img: INFJ,
      };
    case "ESFP":
      return {
        frequency: "45 MHz",
        rare: false,
        trait: "自帶高光 / 創新 / 熱情",
        possibility: "8.5%",
        desc: "45 MHz 是一個被廣泛使用的無線電頻率，你能自在地穿梭於人群中，討厭拘束，嚮往自由自在的生活、熱衷於改變帶來的新鮮感；在穿搭上有自己的堅持。",
        impression: "人見人愛的社交達人，有你在的場子絕對不怕無聊！",
        should:
          "活在當下，享受生命中的每一刻，用你的大膽、創新鼓舞身邊的人展開冒險吧！",
        match: ["ENTP", "ISFJ"],
        incompatible: ["ISTJ", "ENTJ"],
        img: ESFP,
      };
    case "ENTP":
      return {
        frequency: "142.4 MHz",
        rare: false,
        trait: "靈感 / 敏捷 / 辯論",
        possibility: "3.2%",
        desc: "142.4 MHz 擁有高頻、高速的特質，你思路敏捷、善於分析，討厭單一重複性的工作，腦中充滿著創新想法，心直口快，喜歡與人交流自己的想法觀點。",
        impression: "勇於挑戰常規，但愛辯論的性格有時會讓人有點敬而遠之。",
        should:
          "滿足你無限的好奇心，持續了解新事物，讓創新的想法成為你前進的動力來源。",
        match: ["ESFP", "INTJ"],
        incompatible: ["ESFJ", "ISFJ"],
        img: ENTP,
      };
    case "ENFP":
      return {
        frequency: "333 MHz",
        rare: false,
        trait: "熱情 / 夢想 / 自由",
        possibility: "8.1%",
        desc: "333 MHz 是由氧分子發射的頻率，你友善親切，享受與人建立關係，活出自己的價值對你十分重要，喜歡自由、富有創意，擁有很好的交際能力。",
        impression:
          "有點天馬行空，但朝氣又樂觀的態度，總能為朋友帶來許多啟發。",
        should:
          "對於未知的人事物抱持著理解的態度，別跟著主流走，繼續活出自己的顏色。",
        match: ["ESTP", "INFJ"],
        incompatible: ["ENTJ", "ISTJ"],
        img: ENFP,
      };
    case "ESFJ":
      return {
        frequency: "1 MHz",
        rare: false,
        trait: "慷慨 / 樂於助人 / 實踐",
        possibility: "12%",
        desc: "1 MHz 常被用於近距離通訊，你的快樂來自於幫助別人，總能細膩的察覺別人的煩惱，做事時細膩老實，喜歡有完整的計畫，在被拒絕時容易感到失落。",
        impression: "忠厚老實的朋友，別人的稱讚是自我肯定的重要來源。",
        should: "成為夥伴心中可靠的存在，但記得別讓不懂拒絕成為你的弱點。",
        match: ["ISFP", "ENTJ"],
        incompatible: ["ENTP", "INTP"],
        img: ESFJ,
      };
    case "ISFJ":
      return {
        frequency: "5500 MHz",
        rare: false,
        trait: "無私 / 細膩 / 執著",
        possibility: "14.1%",
        desc: "5500 MHz 是現代通訊中的重要頻率，你對自己熟悉的生活會有特別的執著與堅持，善於理解他人的情緒，是朋友訴苦對象的第一人選。",
        impression: "內向但溫柔，記憶力好，比較難接受變化。",
        should: "保持開闊的思想，探索生活的更多可能性，活出理想的自己。",
        match: ["ESFP", "INTJ"],
        incompatible: ["ESTP", "INTP"],
        img: ISFJ,
      };
    default:
      return {};
  }
};
